import _ from 'lodash';
import dateUtil from '../utils/dates';
import dates from '../utils/dates';
import { formatBigNumber } from '../utils/numbers';
import GlobalConst from '../utils/GlobalConst';
import { createComscoreTreeStrings } from '../utils/func';
import UserManager from '../core/UserManager';
import { checkPureObject } from '../utils/func';

export class Segment {

	static TYPES = {
		0: 'video',
		1: 'channel',
		100: 'facebook',
		101: 'instagram'
	};

	static fromJSON(json) {
		const params = { ...json.params };
		const ctl_params = { ...json.ctl_params };
		let type = json.segment_type;

		const getStatistics = json => {
			const values = { ...json.statistics };
			switch (type) {
				case 'channel':
					return {
						channelsCount: values.items_count,
						engageRate: values.engage_rate,
						subscribersCount: values.subscribers,
						subscribersPerChannel: values.subscribers_per_channel,
						topChannels: values.top_three_items ? _.map(json.statistics.top_three_items, channel => ({
							id: channel.id,
							image: channel.image_url,
							title: channel.title
						})) : [],
						videosCount: values.videos_count,
						viewsPerChannel: values.views_per_channel,
						ctr: values.ctr,
						ctrV: values.ctr_v,
						videoViewRate: values.video_view_rate,
						averageCPV: values.average_cpv,
						itemsCount: values.items_count,
						views: values.views,
						likes: values.likes,
						dislikes: values.dislikes,
						sentiment: values.sentiment,
						auditedVideos: values.audited_videos || values.videos_scored,
						monthlySubscribers: values.last_30day_subscribers,
						monthlyViews: values.last_30day_views,
						avgScore: values.average_brand_safety_score,
						vettingStatus: {
							percentage: values.vetted_items_count/values.items_count*100,
							vettedItems: values.vetted_items_count,
							safeItems: values.safe_items_count,
							unSafeItems: values.unsafe_items_count,
							suitableItems: values.suitable_items_count,
							unSuitableItems: values.unsuitable_items_count
						},
						videoExclusionFilename: values.video_exclusion_filename
					};
				case 'video':
					return {
						engageRate: values.engage_rate,
						thirtyDaysViewsCount: values.thirty_days_views_count,
						topVideos: values.top_three_items ? _.map(values.top_three_items, video => ({
							id: video.id,
							image: video.image_url,
							title: video.title
						})) : [],
						videosCount: values.items_count,
						views: values.views,
						viewsPerVideo: values.views_per_video,
						ctr: values.ctr,
						ctrV: values.ctr_v,
						videoViewRate: values.video_view_rate,
						averageCPV: values.average_cpv,
						itemsCount: values.items_count,
						likes: values.likes,
						dislikes: values.dislikes,
						sentiment: values.sentiment,
						monthlyViews: values.last_30day_views,
						avgScore: values.average_brand_safety_score,
						vettingStatus: {
							percentage: values.vetted_items_count/values.items_count*100,
							vettedItems: values.vetted_items_count,
							safeItems: values.safe_items_count,
							unSafeItems: values.unsafe_items_count,
							suitableItems: values.suitable_items_count,
							unSuitableItems: values.unsuitable_items_count
						},
						videoExclusionFilename: values.video_exclusion_filename
					};
				case 'instagram':
				case 'facebook':
					return {
						engageRate: values.engage_rate,
						subscribersCount: values.subscribers,
						subscribersPerChannel: values.subscribers_per_channel,
						topChannels: values.top_three_items ? _.map(json.statistics.top_three_items, channel => ({
							id: channel.id,
							image: channel.image_url,
							title: channel.title
						})) : [],
						videosCount: values.videos_count,
						viewsPerChannel: values.views_per_channel,
						ctr: values.ctr,
						ctrV: values.ctr_v,
						videoViewRate: values.video_view_rate,
						averageCPV: values.average_cpv,
						itemsCount: values.items_count,
						views: values.views,
						likes: values.likes,
						dislikes: values.dislikes,
						sentiment: values.sentiment,
						auditedVideos: values.audited_videos || values.videos_scored,
						monthlySubscribers: values.last_30day_subscribers,
						monthlyViews: values.last_30day_views,
						avgScore: values.average_brand_safety_score,
						vettingStatus: {
							percentage: values.vetted_items_count/values.items_count*100,
							vettedItems: values.vetted_items_count,
							safeItems: values.safe_items_count,
							unSafeItems: values.unsafe_items_count,
							suitableItems: values.suitable_items_count,
							unSuitableItems: values.unsuitable_items_count
						},
						videoExclusionFilename: values.video_exclusion_filename
					};
			}
			return {};
		};

		const generateMinMaxString = str => {
			const strSplit = str.split(', ');
			if (!_.isEmpty(strSplit[0]) && !_.isEmpty(strSplit[1])) return ({min: strSplit[0], max: strSplit[1], val: `${formatBigNumber(strSplit[0], 3)} to ${formatBigNumber(strSplit[1], 3)}`});
			else if (!_.isEmpty(strSplit[0], 3) && _.isEmpty(strSplit[1])) return ({min: strSplit[0], max: null, val: `min ${formatBigNumber(strSplit[0], 3)}`});
			else if (_.isEmpty(strSplit[0]) && !_.isEmpty(strSplit[1])) return ({min: null, max: strSplit[1], val: `max ${formatBigNumber(strSplit[1], 3)}`});
		};

		const setToggleStates = ctl_params => {
			let toggleStates ={
				templates: 'templates_user',
				iab_categories: 'content_categories',
				age_groups: 'age_groups',
				gender: 'gender'
			};
			if (ctl_params.content_categories_3) toggleStates.iab_categories = 'content_categories_3';
			if (ctl_params.exclude_content_categories) toggleStates.iab_categories = 'content_categories';
			if (ctl_params.exclude_content_categories_3) toggleStates.iab_categories = 'content_categories_3';
			if (ctl_params.programmatic_ages) toggleStates.age_groups = 'programmatic_ages';
			if (ctl_params.programmatic_genders) toggleStates.gender = 'programmatic_genders';
			if (ctl_params.score_threshold) toggleStates.brand_suitability = 'standard';
			if (ctl_params.advanced_suitability_categories) toggleStates.brand_suitability = 'garm';
			return toggleStates;
		};

		const getBasicData = json => {
			const mainPlatform = ctl_params.platform_type=='facebook'||ctl_params.platform_type=='instagram' ? 'meta' : 'yt';
			const modalType = mainPlatform+'-'+ctl_params.filter_type;
			return {
				auditId: json.audit_id,
				ctlSynced: json.ctl_is_synced,
				createdAt: dateUtil.dateToLocalZone(json.created_at),
				dynRefreshSettings: json.dynamic_refresh_settings,
				dynRefreshStr: json.dynamic_refresh_str,
				errorCreating: json.statistics?.error,
				id: json.id || json.template_id,
				isEditable: json.is_editable,
				isRegenerating: json.is_regenerating,
				isShared: json.is_shared,
				isSharedWithOthers: json.is_shared_with_others,
				isStandardFlagged: json.is_chrome_extension_standard_list,
				isVettingComplete: json.is_vetting_complete,
				lastDynRefresh: json.last_dynamic_refresh,
				lastVetted: json.last_vetted_date,
				listFormat: json.segment_type,
				mainPlatform,
				modalType,
				owner: json.owner,
				ownerId: json.owner_id,
				paramsVersion: ctl_params.params_version,
				pending: json.pending,
				productType: ctl_params.filter_type || 'inclusion',
				sharedBy: json.shared_by_email,
				updatedAt: dateUtil.dateToLocalZone(json.updated_at),
				typeName: json.segment_type
			};
		};

		const getFiltersData = json => {
			let editListParams = {};
			_.each(json.ctl_params, (value, key) => {
				if (checkPureObject(value)) {
					const objectKeys = Object.keys(value);
					_.each(objectKeys, childKey => {
						if (!_.isUndefined(value[childKey])&&!_.isNull(value[childKey])) {
							editListParams[`${key}.${childKey}`] = value[childKey];
						}
					});
				} else {
					editListParams[key] = value;
				}
			});
			if (json.source_name) editListParams.source_file = { name: json.source_name };
			return editListParams;
		}

		return _.extend(new this(), {
			...getBasicData(json),
			statistics: getStatistics(json),
			toggleStates: setToggleStates(ctl_params),
			ctl_params: getFiltersData(json),
			//Filters - CHANGE THIS WITH YT INCLUSION OVERHAUL
			templateId: ctl_params.template_id,
			buildType: ctl_params.build_type,
			title: json.title || json.template_title,
			sourceFile: json.source_name,
			ageGroups: ctl_params.age_groups,
			ageGroupsNA: ctl_params.age_groups_include_na,
			progAge: ctl_params.programmatic_ages,
			gender: ctl_params.gender,
			progGender: ctl_params.programmatic_genders,
			minimumViews: ctl_params.minimum_views,
			minimumViewsNA: ctl_params.minimum_views_include_na,
			minimumSubscribers: ctl_params.minimum_subscribers,
			minimumSubscribersNA: ctl_params.minimum_subscribers_include_na,
			minimumNumVideos: ctl_params.minimum_videos,
			minimumNumVideosNA: ctl_params.minimum_videos_include_na,
			contentCategories: ctl_params.content_categories,
			primaryCategories: ctl_params.relevant_primary_categories,
			excludedCategories: ctl_params.exclude_content_categories,
			primaryCategoriesThree: ctl_params.relevant_primary_categories_3,
			contentCategoriesThree: ctl_params.content_categories_3,
			excludedCategoriesThree: ctl_params.exclude_content_categories_3,
			sentiment: ctl_params.sentiment,
			languages: ctl_params.languages,
			languagesNA: ctl_params.languages_include_na,
			countries: ctl_params.countries,
			countriesNA: ctl_params.countries_include_na,
			scoreThreshold: ctl_params.score_threshold,
			garmSuitability: ctl_params.advanced_suitability_categories,
			useNLPSuitability: ctl_params.nlp_suitability_2_1 ? 'on' : 'off',
			nlpSuitabilitySelections: ctl_params.advanced_suitability_categories_v2,
			contentType: ctl_params.content_type,
			contentQuality: ctl_params.content_quality,
			nlpTopicsInclude: ctl_params.nlp_topics,
			nlpUmbrellasInclude: ctl_params.nlp_umbrellas,
			includeAttributes: ctl_params.attributes,
			languageThreshold: ctl_params.language_threshold_percentage,
			nlpTopicsExclude: ctl_params.exclude_nlp_topics,
			exclusionFile: _.isEmpty(params.exclusion_file) ? null : params.exclusion_file,
			inclusionFile: _.isEmpty(params.inclusion_file) ? null : params.inclusion_file,
			inclusionHitThreshold: ctl_params.inclusion_hit_threshold,
			exclusionHitThreshold: ctl_params.exclusion_hit_threshold,
			viewRate: ctl_params.video_view_rate ? generateMinMaxString(ctl_params.video_view_rate) : {},
			views30d: ctl_params.last_30day_views ? generateMinMaxString(ctl_params.last_30day_views) : {},
			avgCPV: ctl_params.average_cpv ? generateMinMaxString(ctl_params.average_cpv) : {},
			avgCPM: ctl_params.average_cpm ? generateMinMaxString(ctl_params.average_cpm) : {},
			ctrV: ctl_params.ctr_v ? generateMinMaxString(ctl_params.ctr_v) : {},
			ctr: ctl_params.ctr ? generateMinMaxString(ctl_params.ctr) : {},
			vettingStatus: ctl_params.vetting_status,
			minVideoDuration: _.isEmpty(ctl_params.minimum_duration) ? null : ctl_params.minimum_duration,
			maxVideoDuration: _.isEmpty(ctl_params.maximum_duration) ? null : ctl_params.maximum_duration,
			excludeMismatchedLanguages: ctl_params.mismatched_language,
			excludeNonLatin: ctl_params.exclude_non_latin_titles,
			includeUnknownAdsStats: ctl_params.ads_stats_include_na,
			vettedAfterDate: _.isEmpty(ctl_params.vetted_after) ? null : dates.formatToDatePicker(ctl_params.vetted_after),
			iasVerified: !_.isEmpty(ctl_params.ias_verified_date )? true : false,
			isMonetized: ctl_params.is_monetizable,
			lastUploadDate: _.isEmpty(ctl_params.last_upload_date) ? null : dates.formatToDatePicker(ctl_params.last_upload_date),
			comscoreSegments: ctl_params.comscore_segments,
			ethnicities: ctl_params.ethnicities,
			pillars: ctl_params.pillar_associations,
			madeForKidsThreshold: _.isNumber(ctl_params.made_for_kids_threshold_percentage) ? ctl_params.made_for_kids_threshold_percentage : 100,
			videoShortsType: _.isBoolean(ctl_params.is_youtube_short) ? ctl_params.is_youtube_short : null,
			channelShortsType: _.isString(ctl_params.filter_youtube_shorts_views) ? ctl_params.filter_youtube_shorts_views : null,
			channeShortsViews: _.isNumber(ctl_params.minimum_youtube_shorts_views) ? ctl_params.minimum_youtube_shorts_views : null,
			keyWordInclusion: json.ctl_params.client_custom_data?.inclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.inclusion_list_id, name: json.ctl_params.client_custom_data.inclusion_list_name } :
				null,
			keyWordExclusion: json.ctl_params.client_custom_data?.exclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.exclusion_list_id, name: json.ctl_params.client_custom_data.exclusion_list_name } :
				null,
			channelInclusion: json.ctl_params.client_custom_data?.channel_inclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.channel_inclusion_list_id, name: json.ctl_params.client_custom_data.channel_inclusion_list_name } :
				null,
			channelExclusion: json.ctl_params.client_custom_data?.channel_exclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.channel_exclusion_list_id, name: json.ctl_params.client_custom_data.channel_exclusion_list_name } :
				null,
			videoInclusion: json.ctl_params.client_custom_data?.video_inclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.video_inclusion_list_id, name: json.ctl_params.client_custom_data.video_inclusion_list_name } :
				null,
			videoExclusion: json.ctl_params.client_custom_data?.video_exclusion_list_name ?
				{ id: json.ctl_params.client_custom_data.video_exclusion_list_id, name: json.ctl_params.client_custom_data.video_exclusion_list_name } :
				null,
			reachCountries: ctl_params.reach_countries,
			reachCategories: ctl_params.reach_categories
		});
	}

	static toJSON(data, type) {
		const result = {title: data.title};
		if(data.category) {
			result.category = data.category;
		}
		if (type) {
			const itemsMapper = items => _.map(items, 'id');
			result['ids_to_delete'] = itemsMapper(data.itemsToRemove);
			result['ids_to_add'] = itemsMapper(data.itemsToAdd);
			result['ids_to_create'] = itemsMapper(data.itemsToCreate);
			result['filters'] = data.filters;
		}
		return result;
	}

	get isPrivate() {
		return this.category === 'private';
	}

}

export class SegmentOptionsModel {
	static formatOptions = data => {
		const { region } = UserManager.user;
		const { options, channel_templates,  video_templates, export_types } = data;
		const inclusionTemplates = _.filter(channel_templates, item => item.filter_type=='inclusion');
		const channelTemplates = _.isEmpty(inclusionTemplates) ? [] : _.map(inclusionTemplates, temp => Segment.fromJSON(temp));
		const videoTemplates = _.isEmpty(video_templates) ? [] : _.map(video_templates, temp => Segment.fromJSON(temp));
		const clientCustomData = options?.client_custom_data ? options.client_custom_data : {};
		const regionTemplateTemp = process.env.NODE_ENV === 'development' || _.includes(window.location.host, 'rc.viewiq') ?
			_.find(channelTemplates, ['id', GlobalConst.devRegionTemplateIDs[region]]) :
			_.find(channelTemplates, ['id', GlobalConst.prodRegionTemplateIDs[region]]);
		let regionTemplate = {};
		if (_.isObject(regionTemplateTemp)) {
			regionTemplate = { ...regionTemplateTemp };
			regionTemplate.presetTitle = `${regionTemplate.title}`;
			regionTemplate.templateId = regionTemplate.id;
			delete regionTemplate.id;
			delete regionTemplate.title;
		}
		const defaultOption = { id: null, name: '---' };
		const channelListsInclusion = clientCustomData ? _.flatten([ { ...defaultOption }, clientCustomData.channel_list_inclusion ]) : [];
		const channelListsExclusion = clientCustomData ? _.flatten([ { ...defaultOption }, clientCustomData.channel_list_exclusion ]) : [];
		const videoListsInclusion = clientCustomData ? _.flatten([ { ...defaultOption }, clientCustomData.video_list_inclusion ]) : [];
		const videoListsExclusion = clientCustomData ? _.flatten([ { ...defaultOption }, clientCustomData.video_list_exclusion ]) : [];
		const langObject = _.reduce(options.languages, (acc, item) => { acc[item.id]= item.title; return acc; }, {});
		const comscoreStringsUS = createComscoreTreeStrings(options.comscore_segments, 'us');
		const comscoreStringsNonUS = createComscoreTreeStrings(options.comscore_segments, 'non_us');
		const contentCategoriesFlat = _.reduce(options?.content_categories, (acc, opt) => {
			if (!_.isEmpty(opt.children)) {
				acc.push(opt, ..._.map(opt.children, item => ({ value: item.value, parent: opt.value, id: item.value })));
			}
			else {
				acc.push(opt);
			}
			return acc;
		}, []);
		const contentCategoriesThreeFlat = _.reduce(options?.content_categories_3, (acc, opt) => {
			if (!_.isEmpty(opt.children)) {
				acc.push(opt, ..._.map(opt.children, item => ({ value: item.value, parent: opt.value, id: item.id })));
			}
			else {
				acc.push(opt);
			}
			return acc;
		}, []);
		const originalOptions = {
			pageOptionsAvailable: true,
			beLanguagesObj: langObject,
			bsCategories: options.brand_safety_categories,
			pageOptions: {
				categories: options.content_categories,
				buildTypes: options.build_types,
				countries: options.countries,
				languages: options.languages,
				ageGroups: options.age_groups,
				vettingStatus: options.vetting_status,
				gender: options.gender,
				oracleCat: options.oracle_categories,
				contentTypeCat: options.content_type_categories,
				contentQualityCat: options.content_quality_categories,
				adsStats: options.ads_stats,
				comscoreTree: options.comscore_segments,
				comscoreStrings: { 'us': comscoreStringsUS, 'non_us': comscoreStringsNonUS },
				iasVerifiedDate: options.latest_ias,
				channelTemplates,
				videoTemplates,
				ctlType: data.ctl_type,
				listExclusion: clientCustomData?.keyword_list_exclusion,
				listInclusion: clientCustomData?.keyword_list_inclusion,
				pillars: options.pillars,
				associations: options.associations,
				ethnicities: options.ethnicities,
				channelListsInclusion,
				channelListsExclusion,
				videoListsInclusion,
				videoListsExclusion,
				channelYTShorts: options.filter_youtube_shorts_views,
				garmCategories: options.advanced_suitability_categories,
				categoriesThree: options.content_categories_3,
				videoYTShorts: options.is_youtube_short,
				attributes: options.attributes,
				progAge: options.programmatic_ages,
				progGender: options.programmatic_genders,
				productType: options.filter_type,
				reachCountries: options.reach_countries,
				reachCategories: options.reach_categories,
				garmCategoriesV2: options.brand_safety_categories_v2
			},
			regionTemplate,
			rawOptions: {
				channel_templates: inclusionTemplates,
				content_categories_3: options?.content_categories_3,
				export_types,
				keyword_list_inclusion: clientCustomData?.keyword_list_inclusion,
				keyword_list_exclusion: clientCustomData?.keyword_list_exclusion,
				content_categories_3_flat: contentCategoriesThreeFlat,
				video_templates: data.video_templates
			}
		};
		const ytExclusionOptions = {
			segment_type: [{id: 1, name: 'Channels'}],
			channel_templates_exclusion: data.templates?.exclusion__youtube_channel,
			video_templates_exclusion: data.templates?.exclusion__youtube_video,
			channel_templates_user_exclusion: _.filter(data.templates?.exclusion__youtube_channel, tmpl => {return UserManager.user.id == tmpl.owner}),
			video_templates_user_exclusion: _.filter(data.templates?.exclusion__youtube_video, tmpl => {return UserManager.user.id == tmpl.owner}),
			client_custom_data: options?.client_custom_data,
			advanced_suitability_categories: options?.advanced_suitability_categories,
			content_categories: options?.content_categories,
			content_categories_3: options?.content_categories_3,
			content_categories_flat: contentCategoriesFlat,
			content_categories_3_flat: contentCategoriesThreeFlat,
			languages: options?.languages,
			exclusion_attributes: options?.exclusion_attributes,
			vetting_status: options?.vetting_status,
			gender: options?.gender,
			age_groups: options?.age_groups,
			content_type_categories: options?.content_type_categories,
			content_quality_categories: options?.content_quality_categories,
			programmatic_ages: options?.programmatic_ages,
			programmatic_genders: options?.programmatic_genders,
			reach_countries: options?.reach_countries,
			reach_categories: options?.reach_categories
		};
		const metaExclusionOptions = {
			facebook_templates_exclusion: data.templates?.exclusion__facebook,
			instagram_templates_exclusion: data.templates?.exclusion__instagram,
			facebook_templates_user_exclusion: _.filter(data.templates?.exclusion__facebook, tmpl => {return UserManager.user.id == tmpl.owner}),
			instagram_templates_user_exclusion: _.filter(data.templates?.exclusion__instagram, tmpl => {return UserManager.user.id == tmpl.owner}),
			meta_placement_type: options?.meta_placement_type,
			meta_publisher_type: options?.meta_publisher_type,
			inventory_placement: options?.inventory_placement,
			advanced_suitability_categories: options?.advanced_suitability_categories,
			content_categories: options?.content_categories,
			content_categories_3: options?.content_categories_3,
			content_categories_flat: contentCategoriesFlat,
			content_categories_3_flat: contentCategoriesThreeFlat,
			languages: options?.languages,
			exclusion_attributes: options?.exclusion_attributes,
			vetting_status: options?.vetting_status,
			gender: options?.gender,
			age_groups: options?.age_groups,
			content_type_categories: options?.content_type_categories,
			content_quality_categories: options?.content_quality_categories,
			programmatic_ages: options?.programmatic_ages,
			programmatic_genders: options?.programmatic_genders,
			reach_countries: options?.reach_countries,
			reach_categories: options?.reach_categories
		};
		const metaInclusionOptions = {
			facebook_templates_inclusion: data.templates?.inclusion__facebook,
			instagram_templates_inclusion: data.templates?.inclusion__instagram,
			facebook_templates_user_inclusion: _.filter(data.templates?.inclusion__facebook, tmpl => {return UserManager.user.id == tmpl.owner}),
			instagram_templates_user_inclusion: _.filter(data.templates?.inclusion__instagram, tmpl => {return UserManager.user.id == tmpl.owner}),
			meta_placement_type: options?.meta_placement_type,
			meta_publisher_type: options?.meta_publisher_type,
			inventory_placement: options?.inventory_placement,
			advanced_suitability_categories: options?.advanced_suitability_categories,
			content_categories: options?.content_categories,
			content_categories_3: options?.content_categories_3,
			content_categories_flat: contentCategoriesFlat,
			content_categories_3_flat: contentCategoriesThreeFlat,
			languages: options?.languages,
			inclusion_attributes: options?.attributes,
			vetting_status: options?.vetting_status,
			gender: options?.gender,
			age_groups: options?.age_groups,
			content_type_categories: options?.content_type_categories,
			content_quality_categories: options?.content_quality_categories,
			programmatic_ages: options?.programmatic_ages,
			programmatic_genders: options?.programmatic_genders
		};
		return {
			originalOptions,
			ytExclusionOptions,
			metaExclusionOptions,
			metaInclusionOptions
		};
	}

	static fromJSON(json) {
		const optionsFormatted = SegmentOptionsModel.formatOptions(json);
		return {
			originalOptions: optionsFormatted.originalOptions,
			ytExclusionOptions: optionsFormatted.ytExclusionOptions,
			metaExclusionOptions: optionsFormatted.metaExclusionOptions,
			metaInclusionOptions: optionsFormatted.metaInclusionOptions
		};
	}

}

export class SegmentChunkedExportModel {
	static toJSON(data) {
		return {...data};
	}
}
