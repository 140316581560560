import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import $ from 'jquery';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { Link } from 'react-router-dom';
import GlobalConst from '../../utils/GlobalConst';
import RouterHelper from '../../core/RouterHelper';
import ShallowRenderedComponent from './ShallowRenderedComponent';
import UserManager from '../../core/UserManager';
import withTrackChangedUser from '../../core/withTrackChangedUser';
import WhiteLabelController from '../../core/WhiteLabelController';
import Button from 'components/markup/Button';
import DropdownSelector from '../markup/selectors/DropdownSelector';
// import NotificationsMenu from '../notifications_manager/NotificationsMenu';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft, CiCircleChevRight } from 'react-icons/ci';
import { IoChevronDown, IoChevronUp, IoTimerOutline } from 'react-icons/io5';
import { TfiBarChart } from 'react-icons/tfi';
import { BsBoundingBox, BsGear, BsFolderCheck, BsPerson, BsMeta } from 'react-icons/bs';
import { LuChartNoAxesCombined, LuNotebookText } from 'react-icons/lu';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { LiaClipboardListSolid, LiaToolsSolid } from 'react-icons/lia';
import { AiOutlineExperiment } from 'react-icons/ai';
import { SubmenuL, SubmenuT } from '../markup/Icons';
import StarbucksLogo from '../../img/companies/starbucks_logo.png';
import AppleLogo from '../../img/companies/apple_logo.png';
import AmexLogo from '../../img/companies/amex_logo.png';
import Tooltip from '../markup/Tooltip';

class SideMainMenu extends ShallowRenderedComponent {

	static propTypes = {
		title: PropTypes.string,
		globalOAuthData: PropTypes.object,
		tabOptions: PropTypes.array,
		tabOnSelect: PropTypes.func,
		extraWidth: PropTypes.bool
	}

	static demoCompanies = [
		{ id: 0, title: 'Starbucks Corporation', icon: <img src={StarbucksLogo} className='dp-logo'/> },
		{ id: 1, title: 'Apple Inc', icon: <img src={AppleLogo} className='dp-logo'/> },
		{ id: 3, title: 'American Express', icon: <img src={AmexLogo} className='dp-logo'/> }
	]

	constructor(props) {
		super(props);
		this.state = {
			id: 'side-main-menu',
			openedPage: window.location.pathname,
			parentShowId: null,
			showProfileDropdown: false,
			showNotificationsContainer: false,
			updatingNotification: false,
			latestNotification: null,
			menuExpanded: false,
			selectedCompany: 0
		};
		this.initialState = { ...this.state };
	}

	componentDidMount() {
		super.componentDidMount();
		this.setAutoClose();
		PubSub.subscribe('CLEAR_MENU_PAGE', () => this.setState({ openedPage: window.location.pathname }));
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	setAutoClose = () => {
		const { id } = this.state;
		const hideExpandedItems = () => this.hideExpandedItems();
		document.body.addEventListener('click', function (event) {
			const mainMenu = document.getElementById(id);
			const checkRecursively = (offsetParent, id) => {
				if (offsetParent?.id==id) return true;
				if (!_.isEmpty(offsetParent?.offsetParent)) {
					return checkRecursively(offsetParent?.offsetParent, id);
				}
				return false;
			}
			if (!mainMenu?.contains(event.target) && !checkRecursively(event.target.offsetParent, id) && event.target.offsetParent !== null) {
				hideExpandedItems();
			}
		});
	}

	hideExpandedItems = () => {
		const { parentShowId, showProfileDropdown, showNotificationsContainer, menuExpanded } = this.state;
		if (!_.isNull(parentShowId) || showProfileDropdown || showNotificationsContainer || menuExpanded) {
			this.setState({ parentShowId: null, showProfileDropdown: false, showNotificationsContainer: false, menuExpanded: false });
		}
	}

	isImpersonating = () => {
		const originalAuthToken = localStorage.getItem('auth_token_original_user');
		const currentAuthToken = localStorage.getItem('auth_token');
		return !_.isEmpty(originalAuthToken) && !(originalAuthToken === currentAuthToken);
	}

	scrollToTop() {
		$('html, body').animate({ scrollTop: 0 }, 'fast');
		return null;
	}

	onPathClick = (path) => {
		this.scrollToTop();
		this.setState({ openedPage: path });
		if (window.location.pathname.includes('/exports_manager')) this.props.onFetchExportsStatus();
	}

	onClickParentItem = path => {
		const { parentShowId } = this.state;
		if (path==parentShowId) this.setState({ parentShowId: null, menuExpanded: true });
		else this.setState({ parentShowId: path, showNotificationsContainer: false, showProfileDropdown: false, menuExpanded: true });
	}

	onToggleNotifications = () => {
		this.setState({ showNotificationsContainer: !this.state.showNotificationsContainer, parentShowId: null, showProfileDropdown: false });
	}

	showNotification = notification => {
		this.setState({ latestNotification: notification, updatingNotification: false });
	}

	onReadNotification = id => {
		this.setState({ updatingNotification: true }, () => PubSub.publish('READ_NOTIFICATION', {id}));
	}

	changeCompany = opt => {
		this.setState({ selectedCompany: opt });
	}

	renderSectionSeparator = (sectionName=null) => {
		return <div className='section-separator'>
			{sectionName && <div className='section-name'>{sectionName}</div>}
			<div className='separator-line'/>
		</div>
	}

	renderImpersonationBar = () => {
		const { firstName, lastName } = UserManager.user;
		return <div className='impersonation-bar'>
			<div className='bar-text'>You are logged in as<br />{firstName} {lastName}</div>
			<Button className='btn return-btn' onClick={() => UserManager.loginAsOriginalUser()}>Back to my account</Button>
		</div>;
	}

	renderExportStatus = () => {
		const { exportsStatus } = this.props;
		if (exportsStatus.Complete > 0) {
			return <div className='exports-status'>
				<div className='completed'>{exportsStatus.Complete > 99 ? 99 : exportsStatus.Complete}</div>
				{exportsStatus.Processing > 0 && <div className='processing completed'><i className='fa fa-refresh rotating-i'/></div>}
			</div>;
		} else if (!exportsStatus.Complete && exportsStatus.Processing > 0) {
			return <div className='exports-status'>
				<div className='processing'><i className='fa fa-refresh rotating-i'/></div>
			</div>;
		}
		return null;
	}

	renderSinglePathItem = (path, title, isVisible=true, icon=null, extra=null) => {
		const { menuExpanded } = this.state;
		if (isVisible) {
			const { openedPage } = this.state;
			const selectedItem = path == openedPage || openedPage.includes(path);
			return <Link data-testid={path} to={path} className={cx('single-item', {'selected':selectedItem})} onClick={() => this.onPathClick(path)} reloadDocument>
				<Tooltip className='d-flex' tip={!menuExpanded ? title : ''}>
					{icon && <IconContext.Provider value={{ style: { fontSize: '22px', color: selectedItem ? '#fff' : '#706F6F' } }}>{icon}</IconContext.Provider>}
					<div className='expanded-area ml-10'>
						{title}
					</div>
					{extra}
				</Tooltip>
			</Link>;
		}
		return null;
	}

	renderParentItem = (path, title, children, isVisible = true, icon=null) => {
		const { parentShowId, openedPage, menuExpanded } = this.state;
		const selectedItem = openedPage.includes(path);
		const parentMenuOpened = parentShowId===path;
		return <div className={cx('has-children', {hidden: !isVisible})} >
			<span className={cx('parent-layer-menu', {'opened':parentMenuOpened,'selected': selectedItem})} data-testid={path} onClick={() => this.onClickParentItem(path)}>
				<Tooltip className='d-flex' tip={!menuExpanded ? title : ''}>
				{icon && <IconContext.Provider value={{ style: { fontSize: '22px', color: selectedItem&&!parentMenuOpened ? '#fff' : '#706F6F' } }}>{icon}</IconContext.Provider>}
					<div className='expanded-area ml-10'>
						{title}
						<IconContext.Provider value={{ style: { fontSize: '18px', color: selectedItem&&!parentMenuOpened ? '#fff' : '#706F6F', verticalAlign: 'middle' } }}>
							{parentMenuOpened ? <IoChevronUp/> : <IoChevronDown/>}
						</IconContext.Provider>
					</div>
				</Tooltip>
			</span>
			<div id={path} className={cx('child-menu', { 'visible': parentMenuOpened })}>{children}</div>
		</div>;
	}

	renderSubmenuChildItem = (path, title, isVisible = true) => {
		if (isVisible) {
			return <div key={title} className='child-item'>
				<div className='link-icon'><SubmenuL className='link-l'/><SubmenuT className='link-t'/></div>
				<Link data-testid={path} to={path} onClick={() => this.onPathClick(path)} reloadDocument>{title}</Link>
			</div>;
		}
	}

	renderNavItems() {
		const { canAccessAuditQueue, canAccessBlocklist, canAccessBuild, canAccessBSTE, canAccessDataTracker, canAccessInspect, canAccessNotificationsManagerCreate,
			canAccessDomainManager, canAccessPacingReport, canAccessReview, canAccessMyCustomData, canAccessUmbrellaTopics, canAccessCustomTagger,
			canAccessForecasting, canAccessResearch, canAccessTools, canAccessUserAnalytics, canAccessUserManager, canAccessAdmin, canAccessCIDManager,
			canAccessReportingOpportunities, canAccessReportingAccounts,domainAccessToBuild, canAccessReporting, canAccessComscoreEditor,
			domainAccessToExportsManager, canAccessExperimentalFeatures, canAccessExplore, canAccessMetaExperimental, canAccessCPRScript } = UserManager.user;

		const viewIQAccess = canAccessResearch || (canAccessBuild&&domainAccessToBuild) || canAccessInspect;
		const activateIQAccess = canAccessForecasting || canAccessPacingReport || (canAccessReporting&&(canAccessReportingAccounts||canAccessReportingOpportunities)) || canAccessReview;
		const adminToolsAccess = domainAccessToExportsManager || ((canAccessMyCustomData || canAccessAuditQueue || canAccessCustomTagger)&&canAccessTools) ||
			((canAccessUserAnalytics || canAccessUserManager || canAccessNotificationsManagerCreate || canAccessDomainManager || canAccessBSTE || canAccessBlocklist ||
				canAccessDataTracker || canAccessComscoreEditor || canAccessUmbrellaTopics || canAccessCIDManager || canAccessCPRScript)&&canAccessAdmin);
		const experimentalAccess = canAccessExperimentalFeatures && (canAccessExplore || canAccessMetaExperimental);

		return <div className='navitems-container'>
			{viewIQAccess && this.renderSectionSeparator('ViewIQ')}
			<div className='section-items'>
				{this.renderSinglePathItem('/insights', 'Insights', canAccessResearch, <TfiBarChart />)}
				{this.renderSinglePathItem('/build', 'Build', canAccessBuild&&domainAccessToBuild, <BsBoundingBox />)}
				{this.renderSinglePathItem('/inspect', 'Inspect', canAccessInspect, <LuChartNoAxesCombined />)}
			</div>

			{activateIQAccess && this.renderSectionSeparator('ActivateIQ')}
			<div className='section-items'>
				{this.renderSinglePathItem('/forecasting', 'Forecasting', canAccessForecasting, <TiWeatherPartlySunny />)}
				{this.renderSinglePathItem('/pacing_report', 'Pacing Report', canAccessPacingReport, <IoTimerOutline />)}
				{this.renderSinglePathItem('/report_plus', 'Reporting+', canAccessReporting&&(canAccessReportingAccounts||canAccessReportingOpportunities), <LiaClipboardListSolid />)}
				{this.renderSinglePathItem('/review', 'Review', canAccessReview, <LuNotebookText />)}
			</div>

			{adminToolsAccess && this.renderSectionSeparator()}
			<div className='section-items'>
				{this.renderSinglePathItem('/exports_manager', 'Exports', domainAccessToExportsManager, <BsFolderCheck />, this.renderExportStatus())}
				{this.renderParentItem('/tools/', 'Tools', [
					this.renderSubmenuChildItem('/tools/custom_data', 'My Custom Data', canAccessMyCustomData),
					this.renderSubmenuChildItem('/tools/audit_queue', 'Audit Queue', canAccessAuditQueue),
					this.renderSubmenuChildItem('/tools/custom_tagger', 'Custom Tagger', canAccessCustomTagger)
				], canAccessTools, <LiaToolsSolid/>)}
				{this.renderParentItem('/admin/', 'Admin', [
					this.renderSubmenuChildItem('/admin/user_analytics', 'User Analytics', canAccessUserAnalytics),
					this.renderSubmenuChildItem('/admin/users', 'User List', canAccessUserManager),
					this.renderSubmenuChildItem('/admin/notifications_manager', 'In App Notifications', canAccessNotificationsManagerCreate),
					this.renderSubmenuChildItem('/admin/domain_manager', 'Domain Manager', canAccessDomainManager),
					this.renderSubmenuChildItem('/admin/keywords_editor', 'Keywords Editor', canAccessBSTE),
					this.renderSubmenuChildItem('/admin/blocklist_manager', 'Blocklist Manager', canAccessBlocklist),
					this.renderSubmenuChildItem('/admin/data_tracker', 'Data Tracker', canAccessDataTracker),
					this.renderSubmenuChildItem('/admin/comscore_editor', 'Comscore Editor', canAccessComscoreEditor),
					this.renderSubmenuChildItem('/admin/umbrella_topics', 'Umbrella Topics', canAccessUmbrellaTopics),
					this.renderSubmenuChildItem('/admin/cid_manager', 'CID Manager', canAccessCIDManager),
					this.renderSubmenuChildItem('/admin/cpr_script', 'CPR Script', canAccessCPRScript)
				], canAccessAdmin, <BsGear />)}
			</div>

			{experimentalAccess && this.renderSectionSeparator('Experimental Features')}
			<div className='section-items'>
				{this.renderSinglePathItem('/explore', 'Explore', canAccessExplore, <AiOutlineExperiment/>)}
				{this.renderSinglePathItem('/meta_export', 'Meta Export', canAccessMetaExperimental, <BsMeta/>)}
			</div>

			{this.renderSectionSeparator()}
			<div className='section-items'>
				{this.renderSinglePathItem('/my_profile', 'My Profile', true, <BsPerson/>)}
			</div>

			{this.renderSectionSeparator()}
			<DropdownSelector id='companies' className='header-dropdown' options={SideMainMenu.demoCompanies} selections={[this.state.selectedCompany]} onChange={opt => this.changeCompany(opt)}
				idKey='id' titleKey='title' noSelectedClass={true} titleBySelection={true} multiselect={false} showChevron={true} selectionType='id'/>
			{this.isImpersonating() && this.renderImpersonationBar()}
		</div>;
	}

	renderLatestNotification = () => {
		if (this.state.latestNotification) {
			const { latestNotification: { id, type, message, linkText, link }, updatingNotification } =  this.state;
			const className = GlobalConst.iconOptions[type]?.class;
			const icon = GlobalConst.iconOptions[type]?.icon;
			return <div className={cx('page-header-notification',className)}>
				{icon}
				<div className='message'>
					<span className='description'>{message}</span>
					{linkText && link && <a href={link} target='_blank'>{linkText}</a>}
				</div>
				<span className={cx('material-symbols-outlined read-btn',{'disabled':updatingNotification})} onClick={() => this.onReadNotification(id)}>
					close
				</span>
			</div>;
		}
		return null;
	}

	render() {
		const { menuExpanded } = this.state;
		const { children } = this.props;
		const { logoUrl } = UserManager.user;
		const siteLogoURL = window?.location?.host==='www.viewiq.com' || window?.location?.host==='rc.viewiq.com' ? logoUrl : WhiteLabelController.siteAssets.logo;
		const favicon = WhiteLabelController.siteAssets.favicon || require('../../favicon.png');
		return <div>
			<div className={cx('side-main-menu',{'closed':!menuExpanded})} id='side-main-menu'>
			<div className='main-logo-container'>
				{menuExpanded ?
					<div className='asset-container'>
						<Link to={RouterHelper.takeHome()} reloadDocument={true}>
							<img className='logo' src={siteLogoURL}/>
						</Link>
						{UserManager.user.isAdminUser && <span className='version'>{`${process.env.VIQ_VERSION}`}</span>}
					</div> :
					<img className='favicon' src={favicon}/>}
				<div className='expand-control' onClick={() => this.setState({ menuExpanded: !menuExpanded })}>
					<IconContext.Provider value={{ style: { fontSize: '20px', color: '#979797', backgroundColor: '#fff', borderRadius: '50px', cursor: 'pointer' } }}>
						{menuExpanded ? <CiCircleChevLeft onClick={this.hideExpandedItems} /> : <CiCircleChevRight onClick={() => this.setState({ menuExpanded: true })} />}
					</IconContext.Provider>
				</div>
				</div>
				{this.renderNavItems()}
				{/* <NotificationsMenu showNotificationsContainer={showNotificationsContainer} onToggleNotifications={this.onToggleNotifications}
						onNotificationToShow={notification => this.showNotification(notification)} /> */}
			</div>
			{/* {this.renderLatestNotification()} */}
			<div style={{minWidth:'1350px',marginLeft:'50px'}}>
				{children}
			</div>
		</div>;
	}
}

export default withTrackChangedUser(SideMainMenu);
